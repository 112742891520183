#actuality .container a,
#events .container .g052o2gw9p {
  display: none;
}

#actuality .container a:nth-child(1),
#actuality .container a:nth-child(2),
#events .container .g052o2gw9p:nth-child(1),
#events .container .g052o2gw9p:nth-child(2),
#events .container .g052o2gw9p:nth-child(3),
#events .container .g052o2gw9p:nth-child(4) {
  display: block;
}

.chapo {
  font-size: 1rem;
  font-style: italic;
  line-height: 1.5rem;
  margin: 4rem 0;
}

.subtitle {
  margin: 4rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}

.chapo,
.text {
  text-align: justify;
}

.c9nf7p260c {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: rgba(71, 75, 255, 0.075) 0px 2px 3px;
  transition: transform 0.25s ease 0s, box-shadow 0.25s ease 0s;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}

.c9nf7p260c:after {
  content: '';
  display: block;
  padding-bottom: 150%;
}

.c9nf7p260c:hover {
  box-shadow: rgba(71, 75, 265, 0.075) 0px 8px 12px;
  transform: scale(1.03);
}

.c9nf7p260c img {
  position: absolute;
  height: 100%;
  filter: brightness(80%);
  text-align: center;
}

.post-info {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1rem;
  color: var(--clr-3);
  z-index: 10;
}

.post-info h2 {
  font-size: 1rem;
}

.column {
  gap: 0 !important;
}

.column img {
  width: 100%;
  height: 300px;
  margin-bottom: 1rem;
  object-fit: cover;
  object-position: 0% 50%;
  border-radius: 8px;
}

.post-btn {
  margin-top: 7rem;
  text-align: center;
}

.txt {
  margin-bottom: 1rem;
}
