#events .wrapper:nth-child(1) {
  overflow: hidden;
}

.g052o2gw9p {
  position: relative;
  padding: 1.5rem;
  background-color: #fff;
  height: auto;
  border-radius: 4px;
  box-shadow: rgba(71, 75, 255, 0.075) 0px 2px 3px;
  transition: transform 0.2s ease 0s, box-shadow 0.2s ease 0s;
}

.rnig04d673 {
  position: relative;
  padding: 1.2rem;
  background-color: #fff;
  height: auto;
  border-radius: 4px;
  box-shadow: rgba(71, 75, 255, 0.075) 0px 2px 3px;
  transition: transform 0.2s ease 0s, box-shadow 0.2s ease 0s;
}

.g052o2gw9p img {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 15px;
}

.n88w3e0kx1 {
  height: 150px;
  padding: 2rem;
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(71, 75, 255, 0.075) 0px 2px 3px;
  transition: transform 0.2s ease 0s, box-shadow 0.2s ease 0s;
  cursor: pointer;
}

.n88w3e0kx1 p {
  font-size: 1rem;
  color: var(--clr-1);
  filter: brightness(80%);
  margin-top: 1rem;
}

.item-con {
  text-decoration: none;
}

.item-con h2 {
  font-size: 1.2rem;
  color: var(--clr-1);
}

.d6a0s6g75u {
  margin-top: 0.8rem;
}

.kv3rj3x348,
.d6a0s6g75u {
  font-size: 0.6em;
  font-weight: 400;
  margin-bottom: 1rem;
  color: var(--clr-1);
  filter: brightness(80%);
}

.kyx09ln564 {
  font-size: 1.2em;
  font-weight: 600;
}

.g052o2gw9p a {
  color: var(--clr-1);
  text-decoration: none;
}

.disabled {
  pointer-events: none;
}

.disabled img {
  display: none;
}
