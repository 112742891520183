section {
  padding: 70px 0;
  z-index: 10;
}

footer {
  padding: 50px 0;
  text-align: center;
  font-size: 0.8rem;
}

.wrapper {
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  max-width: 1200px;
  width: 100%;
  z-index: 10;
}

.text-balance {
  text-wrap: balance;
  text-align: center;
}

.text-mb {
  margin-bottom: 1.5rem;
  line-height: 2;
}

.violet {
  background-color: var(--clr-9);
  color: #ffffff !important;
}

.container {
  display: grid;
  gap: 1.8em;
}

.col-2 {
  grid-template-columns: repeat(1, 1fr);
}

.col-3 {
  grid-template-columns: repeat(1, 1fr);
}

.col-title {
  font-size: 1.3rem;
  margin: 1rem 0 1.3rem;
  font-weight: 700;
}

.st0 {
  fill: #343a51;
}

.st1 {
  fill: none;
}
.st2 {
  fill: #ffffff;
}

#actuality .container {
  padding-bottom: 1.5rem;
}

.n7bj7bw282 {
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.quote {
  display: block;
  border-left: 0.2rem solid var(--clr-6);
  padding-left: 2rem;
  line-height: 1.3rem;
  font-size: 0.8rem;
  text-align: justify;
  margin: 2rem 0 2rem;
}

.bullet {
  display: block;
  position: relative;
  margin: 1.2rem 0 0 2rem;
  font-size: 0.8rem;
  line-height: 1.3rem;
  text-align: justify;
}

.bullet::before {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  top: 0.8rem;
  left: -2rem;
  border-radius: 50%;
  background-color: var(--clr-6);
}

.text {
  line-height: 1.8rem;
  font-size: 0.8rem;
  margin-top: 1.5rem;
}

.text-col {
  line-height: 1.8rem;
  font-size: 0.8rem;
  border: 1px solid var(--clr-2);
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.text-center {
  text-align: center;
}

.credit {
  margin: 0 0 3rem;
  padding-left: 1rem;
  font-size: 0.9rem;
  font-style: italic;
  letter-spacing: 0.1rem;
}

.centext {
  text-align: center;
}

.spantext {
  font-size: 1.1rem;
}

.m20 {
  margin: 2rem 0 !important;
}

.rad {
  border-radius: 8px;
}

.center {
  text-align: center;

  p {
    line-height: 2;
  }

  a {
    margin-top: -20px;
  }
}

@media (min-width: 780px) {
  #root::before,
  #root::after {
    display: block;
  }

  .quote {
    font-size: 1rem;
    line-height: 2rem;
  }

  .bullet {
    font-size: 1rem;
    line-height: 2rem;
  }

  .wrapper {
    padding: 0 16px;
  }

  .col-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .col-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .text {
    font-size: 1rem;
    line-height: 2rem;
  }

  .social {
    position: absolute;
    left: 40px;
  }

  .section-title {
    margin-top: 0;
  }

  #articles .wrapper {
    margin-top: 0;
  }

  .uu2989o8pv p:nth-child(2) {
    padding-right: 12rem;
    font-size: 1rem;
    line-height: 2rem;
  }

  .uu2989o8pv img {
    display: block;
    height: 250px;
    position: absolute;
    top: -60px;
    right: 40px;
  }

  .b1f53f84ez {
    font-size: 2rem;
  }

  .terminal {
    display: block;
  }

  .mascot {
    height: 700px;
  }

  .hello {
    display: block;
  }

  .hero-img {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0;
  }

  .img-wallonie {
    height: 11rem;
    float: right;
    margin-top: -70px;
  }

  .img-wallonia {
    height: 8rem;
    float: right;
    margin-top: -50px;
  }

  section {
    padding: 110px 0;
  }

  .fail {
    display: block;
    width: 100%;
    margin: -200px 0 3rem;
  }

  .about-title {
    grid-column: 1 / 3;
    font-size: 3.8rem;
    margin-left: 80px;
  }

  .about-wrapper {
    grid-column: 2 / 4;
    margin-left: -80px;
    padding-right: 80px;
  }

  .about-wrapper p {
    font-size: 0.8rem;
    line-height: 2.2rem;
  }

  .pa14fk32w7 {
    border-radius: 8px;
    height: 150px;
  }

  .about-wrapper p {
    font-size: 1rem;
    line-height: 2.5rem;
  }

  .pa14fk32w7:nth-child(1) img,
  .pa14fk32w7:nth-child(2) img {
    position: absolute;
    top: -20px;
    right: 40px;
    height: 150px;
  }

  .pa14fk32w7:nth-child(3) img {
    position: absolute;
    top: -20px;
    right: 40px;
    bottom: 0;
    height: 170px;
  }

  .pa14fk32w7:nth-child(4) img {
    position: absolute;
    top: -20px;
    right: 0;
    bottom: 0;
    height: 170px;
  }

  .pa14fk32w7 p {
    font-size: 1.8em;
    font-weight: 600;
    margin: 0.8em;
  }

  .partners-text {
    margin-top: -2rem;
    font-size: 1rem;
    line-height: 2rem;
  }

  footer {
    font-size: 1rem;
  }

  .rnig04d673 {
    border-radius: 8px;
    padding: 2rem;
  }

  .g200mv1sa1 {
    font-size: 1.8em;
    font-weight: 600;
    text-decoration: none;
    color: var(--clr-1);
  }

  #hero {
    height: 920px;
    font-family: rubik;
  }

  .post-info {
    padding: 2rem;
  }

  .post-info h2 {
    font-size: 2.2rem;
  }

  .contact-text {
    padding-top: 150px;
    padding-left: 4rem;
  }

  .contact-text p:nth-child(1) {
    display: block;
    padding-right: 12rem;
    text-align: justify;
    line-height: 2.5rem;
    margin-bottom: 3.5rem;
  }

  .contact-text p:nth-child(3) {
    font-size: 2rem;
    margin-top: 2rem;
    letter-spacing: 0.2rem;
  }

  .contact-text a {
    font-size: 2rem;
  }

  .social-alt {
    width: 35px;
  }

  .hero-subtitle {
    font-size: 1.5rem;
  }

  .hero-title {
    font-size: 3.8rem;
    font-weight: 600;
    margin-bottom: 3.5rem;
    font-family: rubik, sans-serif;
  }

  .cell {
    height: 20px;
    width: 20px;
  }

  .buttons button {
    margin-top: 0rem;
  }

  .link-btn {
    padding: 1rem 1.5rem;
    border-radius: 0.4rem;
    font-size: 1rem;
  }

  .title-alt {
    font-size: 4rem;
    line-height: 5rem;
    text-wrap: balance;
  }

  .chapo {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }

  .column img {
    height: 500px;
  }

  .g052o2gw9p {
    height: auto;
    padding: 2rem;
    border-radius: 8px;
  }

  .g052o2gw9p img {
    height: 20px;
  }

  .buttons {
    display: flex;
  }

  .buttons button:nth-child(5) {
    margin-top: 0;
  }

  #events .wrapper:nth-child(1) {
    overflow: hidden;
  }

  .kv3rj3x348,
  .d6a0s6g75u {
    font-size: 0.8em;
  }

  .kyx09ln564 {
    font-size: 1.6em;
    font-weight: 600;
  }

  .c9nf7p260c {
    border-radius: 8px;
  }

  #actuality .container a:nth-child(3) {
    display: block;
  }

  .title {
    font-size: 50px;
  }

  .title-bis {
    font-size: 3.5rem;
    margin: 5rem 0 3rem;
  }

  .main-menu {
    padding: 0 50px;
    height: 130px;
  }

  .logo {
    width: 103px;
  }

  .burger {
    width: 33px;
  }

  .cross {
    width: 25px;
  }

  .nav-buttons {
    padding-left: 4rem;
    font-size: 3.5rem;
    line-height: 5rem;
    padding-top: 100px;
    padding-left: 12rem;
  }

  .btn {
    position: absolute;
    top: 50px;
    right: 50px;
  }

  .btn-grid {
    grid-auto-flow: column;
  }
}
