#error .wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 339px);
}

.flex {
  display: flex;
  justify-content: center;
}

.error {
  height: 400px;
}

.error-link {
  text-decoration: none;
  margin-top: 5rem;
}
