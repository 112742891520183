:root {
  --clr-1: #292e42;
  --clr-2: #b7c4cf;
  --clr-3: #f8f9fb;
  --clr-4: #ef2b54;
  --clr-5: #ffa62b;
  --clr-6: #ffd966;
  --clr-7: #49d49d;
  --clr-8: #26bcee;
  --clr-9: #6b32d0;
}

*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: var(--clr-1);
  background-color: var(--clr-6);
  transition: transform 0.3s ease 0s, background-color 0.3s ease 0s;
  font-family: rubik, sans-serif;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
