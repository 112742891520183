.main-menu {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  height: 100px;
  z-index: 1;
}

.alt-menu {
  width: 100%;
  height: 100vh;
  position: fixed;
  visibility: hidden;
  margin-top: 30px;
  transition: transform 0.25s ease 0s, margin-top 0.25s ease 0s;
  background-color: var(--clr-9);
  color: white;
  z-index: 1000;
}

.alt-menu a {
  font-weight: 600;
  text-decoration: none;
  color: white;
  margin: 10px;
  padding-left: 0;
  transition: padding-left 0.2s ease-in-out;
}

.nav-buttons a:hover {
  padding-left: 10px;
}

.is-visible {
  visibility: visible;
  margin-top: 0;
}

.logo,
.burger,
.cross {
  cursor: pointer;
}

.logo {
  width: 75px;
}

.burger {
  width: 24px;
}

.cross {
  width: 18px;
}

button {
  color: var(--clr-3);
  background: var(--clr-1);
  border: none;
  margin: 0 0.6rem;
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  font: inherit;
  cursor: pointer;
  transition: transform 0.2s ease 0s, all 0.2s ease 0s;
}

.buttons {
  display: block;
  font-size: 16px;
  margin: 2rem 0 3rem;
}

.buttons button {
  margin-top: 1rem;
}

.buttons button:nth-child(1):hover {
  background: var(--clr-2);
  color: var(--clr-1);
}

.buttons button:nth-child(2):hover {
  background: var(--clr-9);
}

.buttons button:nth-child(3):hover {
  background: var(--clr-8);
}

.buttons button:nth-child(4):hover {
  background: var(--clr-7);
}

.buttons button:nth-child(5):hover {
  background: var(--clr-6);
  color: var(--clr-1);
}

.buttons button:nth-child(6):hover {
  background: var(--clr-5);
  color: var(--clr-3);
}

.buttons button:nth-child(7):hover {
  background: var(--clr-4);
  color: var(--clr-3);
}

.nav-buttons {
  display: flex;
  flex-direction: column;
  line-height: 3rem;
  padding-top: 70px;
  padding-left: 2rem;
  font-size: 2rem;
}

/* .nav-buttons a:nth-child(6) {
  font-size: 2rem;
} */

.contact-text {
  padding-top: 30px;
  padding-left: 2rem;
  padding-right: 4rem;
}

.contact-text p:nth-child(1) {
  display: none;
}

.contact-text p:nth-child(3) {
  font-size: 1.2rem;
  margin-top: 1.2rem;
  letter-spacing: 0.2rem;
}

.contact-text a {
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0.2rem;
  margin: 0;
}

.btn {
  position: absolute;
  top: 40px;
  right: 32px;
}

.cell {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 15px;
  width: 15px;
}

.purple {
  background-image: url(../../public/svg/link-purple.svg);
}

.blue {
  background-image: url(../../public/svg/link-blue.svg);
}

.green {
  background-image: url(../../public/svg/link-green.svg);
}

.yellow {
  background-image: url(../../public/svg/link-yellow.svg);
}

.orange {
  background-image: url(../../public/svg/link-orange.svg);
}

.red {
  background-image: url(../../public/svg/link-red.svg);
}

.auto {
  height: auto;
}

.margin {
  margin: 4rem 0 8rem;
}

.margin-top {
  margin-top: 4rem;
}

.wrapper:nth-child(2) .margin {
  margin: 4rem 0 4rem;
}

@media (min-width: 780px) {
  .main-menu {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
  }
}

@keyframes example {
  from {
    color: white;
  }
  to {
    color: red;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
