@font-face {
  font-family: "Myriad Pro";
  src: url(../../public/font/MyriadPro.OTF);
}

#root::before {
  content: "";
  display: none;
  background-image: url("../../public/svg/points.svg");
  width: 10rem;
  height: 280px;
  position: fixed;
  top: 15rem;
  left: 0.5rem;
  z-index: 10;
}

#root::after {
  content: "";
  display: none;
  background-image: url("../../public/svg/points.svg");
  width: 10rem;
  height: 280px;
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 10;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

#hero {
  height: 500px;
  font-family: rubik;
}

#about {
  padding-bottom: 0;
}

#skills {
  padding-top: 170px;
}

footer a {
  font-weight: 600;
  text-decoration: none;
  margin-left: 0.5rem;
  color: var(--clr-1);
}

.about-title {
  grid-column: 1 / 4;
  font-size: 1.8rem;
  margin-left: 0;
  z-index: 10;
}

.about-wrapper {
  grid-column: 1 / 4;
  margin-left: 0;
  padding-right: 0;
}

.about-wrapper p {
  font-size: 0.8rem;
  line-height: 1.5rem;
  text-align: justify;
}

.alt-link {
  display: flex;
  align-items: center;
}

.alt-link a {
  font-size: 2rem;
}

.alt-link img {
  height: 2rem;
}

.fail {
  display: none;
}

#skills .wrapper,
#partners .wrapper,
#actuality .wrapper,
#events .wrapper {
  position: relative;
  z-index: 10;
}

#events .wrapper {
  padding-bottom: 1rem;
}

.mascot {
  height: 250px;
  animation: 2.5s cubic-bezier(0.65, 0.2, 0.2, 1) 0s infinite bounce;
  z-index: 10;
}

.hello {
  display: none;
  position: absolute;
  top: 40px;
  right: 30px;
  height: 60px;
  animation: 2.5s cubic-bezier(0.42, 0, 0.58, 1) 250ms infinite bounce2;
}

.hero-img {
  position: relative;
  display: flex;
  justify-content: right;
  margin: -4rem 0 0 0;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 0.5s;
}

.pa14fk32w7 {
  position: relative;
  color: var(--clr-1);
  background-color: var(--clr-6);
  height: 100px;
  border-radius: 4px;
  box-shadow: rgba(71, 75, 255, 0.075) 0px 2px 3px;
  transition: transform 0.25s ease 0s, box-shadow 0.25s ease 0s;
  text-decoration: none;
  cursor: pointer;
}

.pa14fk32w7:nth-child(1) img,
.pa14fk32w7:nth-child(2) img {
  position: absolute;
  top: -15px;
  right: 15px;
  height: 100px;
}

.pa14fk32w7:nth-child(3) img {
  position: absolute;
  top: -20px;
  right: 20px;
  bottom: 0;
  height: 120px;
}

.pa14fk32w7:nth-child(4) img {
  position: absolute;
  top: -20px;
  right: 0;
  bottom: 0;
  height: 120px;
}

.pa14fk32w7 p {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0.8em;
}

.pa14fk32w7:hover,
.g052o2gw9p:hover,
.n88w3e0kx1:hover,
.rnig04d673:hover,
.uu2989o8pv:hover {
  box-shadow: rgba(71, 75, 265, 0.075) 0px 8px 12px;
  transform: scale(1.03);
  cursor: pointer;
}

.hero-subtitle {
  font-family: rubik, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  margin: 3.5rem 0 0;
}

.hero-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 3.5rem;
  font-family: rubik, sans-serif;
}

.w7g2r29tb5 .link-btn {
  display: inline-block;
}

.link-btn {
  position: relative;
  display: block;
  justify-self: center;
  padding: 0.8rem 1.2rem;
  border-radius: 0.3rem;
  transition: transform 0.2s ease 0s, box-shadow 0.2s ease 0s;
  text-decoration: none;
  font-family: rubik, sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.15rem;
  background-color: var(--clr-9);
  color: #fff;
  z-index: 10;
}

.link-btn:hover {
  transform: scale(1.08);
}

.title {
  font-size: 30px;
  text-align: center;
  margin-bottom: 1.3em;
}

.title-cat {
  font-size: 24px;
  font-weight: 700;
  margin: 3em 0 2em;
}

.terminal {
  display: none;
  position: relative;
  margin-top: 6rem;
  padding: 3rem 2rem 1.2rem;
  height: 175px;
  font-family: Myriad Pro, sans-serif;
  font-size: 0.95rem;
  font-weight: 100;
  line-height: 1.7rem;
  letter-spacing: 0.22rem;
  background-color: var(--clr-1);
  color: #fff;
  border-radius: 1rem;
  box-shadow: rgba(71, 75, 255, 0.075) 0px 2px 3px;
  transition: transform 0.25s ease 0s, box-shadow 0.25s ease 0s;
  z-index: 100;
}
.n88w3e0kx1 p {
  font-size: 0.8rem;
}

.dots {
  width: 3rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.img-wallonie {
  height: 6.5rem;
  float: right;
  margin-top: -50px;
}

.img-wallonia {
  height: 4.5rem;
  float: right;
  margin-top: -30px;
}

.img-full {
  width: 100%;
  margin: 0 0 2rem;
  border-radius: 0.5rem;
}

.partner-title {
  font-size: 1.7em;
  font-weight: 600;
  color: var(--clr-6);
  text-decoration-line: none;
}

.g200mv1sa1 {
  font-size: 1em;
  font-weight: 600;
  text-decoration: none;
  color: var(--clr-1);
}

.ma {
  display: grid;
  margin-top: 2rem;
}

.grey {
  color: grey;
}

.light-blue {
  color: #66c7ed;
}

.bg-yellow {
  background-color: var(--clr-3);
}

.space {
  padding-left: 1.1rem;
}

.partners-text {
  margin-top: -1rem;
  line-height: 1.5rem;
  text-align: center;
  max-width: 700px;
  font-size: 0.8rem;
}

.kea788ot36 {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}

#games {
  padding: 70px 0;
}

#games .wrapper .container {
  font-size: 1rem;
  display: flex;
  justify-content: center;
}

.uu2989o8pv {
  position: relative;
  padding: 3rem 3rem 2.5rem;
  color: var(--clr-1);
  background-color: var(--clr-3);
  width: 700px;
  border-radius: 8px;
  box-shadow: rgba(71, 75, 255, 0.075) 0px 2px 3px;
  transition: transform 0.2s ease 0s, box-shadow 0.2s ease 0s;
  text-decoration: none;
  line-height: 2rem;
  cursor: pointer;
}

.uu2989o8pv p:nth-child(2) {
  padding-right: 0rem;
  font-size: 0.8rem;
  line-height: 1.2rem;
  text-align: justify;
}

.uu2989o8pv img {
  display: none;
}

.b1f53f84ez {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 600;
  line-height: 1.7rem;
  letter-spacing: 0.22rem;
}

.secondtitle {
  margin: 3rem 0 2rem;
}

.section-title {
  margin-top: 3rem;
}

#articles .wrapper {
  margin-top: 2rem;
}

.title-alt {
  font-size: 2rem;
  margin-top: 2rem;
}

.title-bis {
  font-size: 2.5rem;
  margin: 5rem 0 3rem;
}

#footer {
  position: relative;
}

.social {
  width: 25px;
  margin: 0 auto 1rem;
}

.social-alt {
  width: 25px;
  margin-top: 50px;
}

.bloc {
  position: relative;
}

.subtitle,
.text,
.link,
.image,
.image-f,
.iframe {
  display: block !important;
}

.iframe-support {
  margin-bottom: 3rem;
}

.image {
  margin: 3rem 0 0;
}

.image-f {
  margin: 3rem 0 0;
  height: auto !important;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin-top: 2rem;
  width: 100%;
  height: 100%;
}

.link {
  display: inline-block !important;
  margin: 2rem 0.5rem 1rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  transition: transform 0.2s ease 0s, box-shadow 0.2s ease 0s;
  text-decoration: none;
  background-color: var(--clr-1);
  color: var(--clr-3);
}

.link:hover {
  transform: scale(1.08);
}

.subtitle:empty,
.text:empty,
.quote:empty,
.bullet:empty {
  display: none;
}

.workshop-links {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.workshop-links a {
  text-decoration: none;
  margin: 0.5rem;
  padding: 1rem 1.2rem;
  border: 1.5px solid var(--clr-1);
  border-radius: 1rem;
  color: var(--clr-1);
}

.workshop-links a:hover {
  transition: transform 0.25s ease 0s, all 0.25s ease 0s;
  background-color: var(--clr-1);
  color: var(--clr-3);
}

iframe {
  border: 0;
  border-radius: 1rem;
  text-align: center;
}

.line {
  width: 100px;
  margin: 0.8rem 0.5rem;
}

.support {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  height: 160px;
}

.support img {
  max-height: 6rem;
  max-width: 10rem;
}

.wrapper-soutien {
  display: flex;
  justify-content: center;
}

.soutien {
  max-height: 250px;
  margin-top: 6rem;
}

.align-center {
  text-align: center;
  margin: 5rem 0 4rem;
}

.align-center img {
  width: 100%;
}

@media (min-width: 780px) {
  .align-center img {
    width: auto;
  }
}

@keyframes bounce {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: -20px;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes bounce2 {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: -30px;
  }
  100% {
    margin-top: 0;
  }
}

.small {
  max-width: 900px !important;
}
